
import { Component, Vue } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { ISelectItem } from "@/types";
import { IWarehouseListRequest, IWarehouse } from "@/types/warehouse";
import { IMaker, IMakerListRequest } from "@/types/maker";
import { IProductListRequest, IProduct } from "@/types/product";
import { ILotCreateRequest, ILot } from "@/types/lot";
import { IUser, IUserListRequest } from "@/types/user";
import {
  IMovementStoring,
  IMovementStoringUpdateRequest
} from "@/types/movement_storing";
import { LotCreateRequest, LotRequest } from "@/models/lot";
import { MovementStoringUpdateRequest } from "@/models/movement_storing";
import { IOrder } from "@/types/order";
import { IMovement, IMovementListRequest } from "@/types/movement";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //movement_storing
  @Action("movement_storing/adminGet")
  public getMovementStoring!: (movement_id: number) => Promise<boolean>;

  @Action("movement_storing/adminUpdate")
  public update!: (data: {
    movement_id: number;
    params: IMovementStoringUpdateRequest;
  }) => Promise<boolean>;

  @Action("movement_storing/adminUpdateQuantity")
  public updateQuantity!: (data: {
    movement_id: number;
    quantity: number;
  }) => Promise<boolean>;

  @Action("movement_storing/adminDelete")
  public deleteMovementStoring!: (movement_id: number) => Promise<boolean>;

  @Getter("movement_storing/single")
  public movement!: IMovementStoring;

  @Mutation("movement_storing/clear")
  public clear!: () => void;

  //movement
  @Action("movement/adminGetList")
  public getMovementList!: (request: IMovementListRequest) => Promise<boolean>;

  @Getter("movement/list")
  public movementList!: IMovement[];

  //warehouse
  @Action("warehouse/adminGetList")
  public getWarehouses!: (params: IWarehouseListRequest) => Promise<boolean>;

  @Getter("warehouse/selectItem")
  public warehouseList!: ISelectItem[];

  @Getter("warehouse/find")
  public findWarehouse!: (id: number) => IWarehouse;

  @Mutation("warehouse/clear")
  public clearWarehouse!: () => void;

  //maker
  @Action("maker/adminGetList")
  public getMakers!: (params: IMakerListRequest) => Promise<boolean>;

  @Getter("maker/selectItem")
  public makerList!: ISelectItem[];

  @Getter("maker/find")
  public findMaker!: (id: number) => IMaker;

  @Mutation("maker/clear")
  public clearMaker!: () => void;

  //product
  @Action("product/adminGetList")
  public getProducts!: (params: IProductListRequest) => Promise<boolean>;

  @Getter("product/find")
  public findProduct!: (id: number) => IProduct;

  @Getter("product/selectItem")
  public productList!: ISelectItem[];

  @Mutation("product/clear")
  public clearProduct!: () => void;

  //lot
  @Action("lot/adminCreateNoneMessage")
  public lotCreate!: (params: ILotCreateRequest) => Promise<boolean>;

  @Action("lot/adminDeleteNoneMessage")
  public deleteLot!: (lot_id: number) => Promise<boolean>;

  @Getter("lot/multiple")
  public lots!: ILot[];

  @Mutation("lot/clear")
  public clearLot!: () => void;

  //order
  @Action("order/adminGet")
  public getOrder!: (id: number) => Promise<boolean>;

  @Action("order/adminIsStoringComplete")
  public getIsStoringComplete!: (order_id: number) => Promise<boolean>;

  @Getter("order/single")
  public order!: IOrder;

  @Mutation("order/clear")
  public clearOrder!: () => void;

  // note
  @Action("note/adminUpdateNote")
  public updateNote!: (
    notes: [
      {
        id: number | null;
        content: string | null;
      }
    ]
  ) => Promise<boolean>;

  //authUser
  @Getter("auth/me")
  public user!: IUser;

  //user
  @Action("user/adminGetList")
  public getUsers!: (params: IUserListRequest) => Promise<boolean>;

  @Getter("user/selectItem")
  public userList!: ISelectItem[];

  @Getter("user/find")
  public findUser!: (id: number) => IUser;

  @Mutation("user/clear")
  public clearUser!: () => void;

  //パラメータ定義
  public params: IMovementStoringUpdateRequest =
    new MovementStoringUpdateRequest();
  public lot_params_create: ILotCreateRequest = new LotCreateRequest();

  //発注ステータス
  public order_status_list = [
    { value: Vue.prototype.$orderStatus.order_registration, text: "発注登録" },
    { value: Vue.prototype.$orderStatus.ordered, text: "発注登録" },
    { value: Vue.prototype.$orderStatus.wait_storing, text: "入荷待ち" },
    { value: Vue.prototype.$orderStatus.stored, text: "入荷済み" },
    { value: Vue.prototype.$orderStatus.cancel_order, text: "発注キャンセル" },
    { value: Vue.prototype.$orderStatus.division_storing, text: "分納" }
  ];

  //変数定義
  public movement_status = Vue.prototype.$movementStatus;
  public order_status = 0;
  public order_quantity = 0;
  public in_store_quantity = 0;
  public remaining_storing_quantity = 0;
  public movement_id = 0;
  public maker_id = 0;
  public order_unit_price = 0;
  public unit = "";
  public lot_no: string | null = "";
  public is_disabled = true;
  public is_readonly = false;
  public remaining_storing_check = false;
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public lazy = false;
  public valid_stock_disp = false;
  public valid_years_disp = false;
  public product_id = 0;
  public customerProductList: ISelectItem[] = [];
  public notes: [
    {
      id: number | null;
      content: string | null;
    }
  ] = [
    {
      id: 0,
      content: null
    }
  ];
  public menu = {
    arrival_date: false
  };

  //ルール設定
  public rules = {
    arrival_date: [(v: string) => !!v || "入荷日は必須です"],
    warehouse_id: [(v: number) => !!v || "入荷倉庫は必須です"],
    maker_id: [(v: number) => !!v || "メーカは必須です"],
    product_id: [(v: number) => !!v || "商品は必須です"],
    exchange_rate: [
      (v: number) => !!v || "為替レートは必須です",
      (v: number) => v > 0 || "0以下は入力できません",
      (v: string) =>
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ],
    buying_unit_price: [
      (v: number) => !!v || v === 0 || "単価は必須です",
      (v: number) => v >= 0 || "0未満は入力できません",
      (v: string) =>
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ],
    remaining_storing_quantity: [
      (v: number) => v >= 0 || "残入荷数量を超えています"
    ],
    user_id: [(v: number) => !!v || "登録者は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.movement_id = Number(this.$route.params.movement_id);
    this.lot_params_create.lots.push(new LotRequest());
    this.clear();
    this.clearOrder();
    this.clearUser();

    await this.getMovementStoring(this.movement_id);

    this.valid_years_disp = this.diffMonth(this.movement.movement_date) > 12;

    this.valid_stock_disp =
      this.movement.quantity != this.movement.current_stock_quantity;

    if (this.valid_years_disp) {
      return;
    }

    await Promise.all([
      this.getUsers({ per_page: 0 }),
      this.getOrder(this.movement.order_id),
      this.getWarehouses({ per_page: 0 }),
      this.getMakers({ per_page: 0 })
    ]);

    this.setDefault();

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private async setDefault() {
    this.params.order_id = this.movement.order_id;
    this.params.supplier_id = this.order.supplier_id;
    this.params.status = this.movement.status;
    this.params.arrival_date = this.movement.arrival_date;
    this.params.warehouse_id = this.movement.warehouse_id;
    this.maker_id = this.movement.maker_id;
    await this.makerSelected();
    this.params.product_id = this.movement.product_id;
    await this.productSelected();
    this.params.lot_id = this.movement.lot_id || 0;
    this.lot_no = this.movement.lot_no;
    this.params.buying_unit_price = Number(
      Number(this.movement.buying_unit_price).toFixed(2)
    );
    this.params.quantity = Number(Number(this.movement.quantity).toFixed(2));
    this.params.order_product_id = this.movement.order_product_id;
    this.params.exchange_rate = this.movement.exchange_rate;
    this.order_status = this.order.order_status;
    this.params.user_id = this.movement.user_id || 0;

    //発注数量、残入荷数量、発注商品メモセット
    this.order.order_product.forEach(order_product => {
      if (order_product.id == this.movement.order_product_id) {
        this.order_quantity = order_product.quantity;
        this.notes[0].id = order_product.note_id;
        this.notes[0].content = order_product.content;
        this.remaining_storing_quantity =
          order_product.remaining_quantity + this.movement.quantity;
        this.order_unit_price = Number(
          Number(order_product.buying_unit_price).toFixed(2)
        );
      }
    });
  }

  //--------
  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時
  public async submitUpdate() {
    this.submit_dialog = false;
    let result = false;

    // lot_idがある場合
    if (this.params.lot_id) {
      // lot_noがない場合は削除
      if (!this.lot_no) {
        result = true;
        this.params.lot_id = null;
      }
      // lot_noがある場合は変更
      if (this.lot_no) {
        this.lot_params_create.lots[0].lot_no = this.lot_no;
        this.lot_params_create.lots[0].product_id = this.params.product_id;
        this.lot_params_create.lots[0].user_id = this.params.user_id;
        result = await this.lotCreate(this.lot_params_create);
        this.params.lot_id = this.lots[0].id;
      }
    }
    //lot_idが無い場合
    if (!this.params.lot_id) {
      result = true;
      this.params.lot_id = null;
    }
    // lot_idが無かったものにlot_noを追加した場合
    if (!this.params.lot_id && this.lot_no) {
      this.lot_params_create.lots[0].lot_no = this.lot_no;
      this.lot_params_create.lots[0].product_id = this.params.product_id;
      this.lot_params_create.lots[0].user_id = this.params.user_id;
      result = await this.lotCreate(this.lot_params_create);
      this.params.lot_id = this.lots[0].id;
    }

    //発注商品メモ更新
    if (result) {
      result = await this.updateNote(this.notes);
    }

    // movemnetの更新
    if (result) {
      result = await this.update({
        movement_id: this.movement_id,
        params: this.params
      });
    }

    //入荷済みチェック
    if (result) {
      result = await this.getIsStoringComplete(this.order.id);
    }

    // ひとつ前の画面に遷移
    if (result) {
      this.$router.go(-1);
    }
  }

  // 数量更新時
  public async submitUpdateQuantity() {
    this.submit_dialog = false;
    let result = false;

    result = await this.updateQuantity({
      movement_id: this.movement_id,
      quantity: this.params.quantity
    });

    //入荷済みチェック
    if (result) {
      result = await this.getIsStoringComplete(this.order.id);
    }

    // ひとつ前の画面に遷移
    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    // 削除
    await this.deleteMovementStoring(this.movement_id);
    //ステータス変更
    await this.getIsStoringComplete(this.order.id);
    // ひとつ前の画面に遷移
    this.$router.go(-1);
  }

  //メーカが選択された際の挙動
  public async makerSelected() {
    this.params.product_id = 0;
    await this.getProducts({
      maker_id: this.maker_id,
      per_page: 0
    });
  }

  //商品が選択された際の挙動
  public async productSelected() {
    const product = this.findProduct(this.params.product_id);
    this.unit = product.unit;
  }

  //リアクティブ残入荷数量計算
  public NowRemainingStoringQuantity() {
    this.remaining_storing_check =
      this.remaining_storing_quantity - this.params.quantity != 0;
    return this.remaining_storing_quantity - this.params.quantity;
  }

  //外貨両替計算
  public currencyExchange() {
    if (!this.params.exchange_rate || this.params.exchange_rate == 0) return;
    this.params.buying_unit_price = Number(
      (this.order_unit_price * this.params.exchange_rate).toFixed(2)
    );
  }
}
